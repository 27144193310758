
          @use "sass:math";
          @import "~@/assets/scss/variables.scss";
          @import "~@/assets/scss/mixins/misc.scss";
        

.edit-survey {
  &__setting {
    margin-bottom: $base-gutter;
  }
  &__header {
    display: flex;
    align-items: center;
    margin-bottom: $base-gutter;

    h4 {
      background-color: rgba($color-primary-1-day, .1);
      border-radius: 50%;
      width: 32px;
      height: 32px;
      margin-right: $base-gutter;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &--wide {
      justify-content: space-between;
    }
    &--end {
      justify-content: flex-end;
    }
  }
  &__page-actions {
    display: flex;
    align-items: center;
    gap: $base-gutter;
    margin-bottom: $base-gutter;

    .esmp-button--small {
      min-width: 35px;
    }
  }
  &__active-page {
    border-color: var(--esmp-ui-button-view-outline-hover-border-color);
    background-color: var(--esmp-ui-button-view-outline-hover-background-color);
  }
  &__grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-column-gap: 10px;
  }
  h3 {
    margin-bottom: $base-gutter;
  }
  &__field {
    margin-bottom: $base-gutter / 2;
    position: relative;
  }
  &__logo-setting {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 10px;
  }
  &__logo {
    height: 69px;
    display: flex;
    align-items: flex-start;

    img {
      height: 100%;
    }
  }
  &__hint {
    font-size: 12px;
  }
  &__url-actions {
    position: absolute;
    right: $base-gutter;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    gap: $base-gutter;
  }
  &__result-types {
    display: flex;
    align-items: center;
    gap: $base-gutter;
  }
  &__right-bar {
    display: flex;
    align-items: center;
  }
  &__expander {
    display: flex;
    align-items: center;
    margin-left: 10px;
    width: 200px;
    &:hover {
      cursor: pointer;
    }

    &-text {
      display: inline-block;
      margin-bottom: -4px;
    }
  }
  &__pagination {
    justify-content: center;
    padding: $base-gutter 0;
  }
}
